import React from "react";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { toast } from "react-toast";


const UseWallet = ({ setOpenWalletFlag, openWalletFlag, setWalletAmount, walletAmount, walletBalance,withDrawHandler }) => {


    function closeModal() {
        setOpenWalletFlag(false)
        setWalletAmount(0)
    }

    function openModal() {
        setOpenWalletFlag(true)
    }

    // const withDrawHandler = ()=>{
      
    //     if(walletAmount>walletBalance){
    //         toast.info("Please add correct amount")
    //     }else{
           
    //         setOpenWalletFlag(false)
    //     }
    // }

    return (
        <>

            <Transition appear show={openWalletFlag} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl  p-6 text-left align-middle shadow-xl transition-all bg-[#fff] ">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        My Wallet
                                    </Dialog.Title>
                                    <div className="mb-5">
                                        <p className="text-sm text-gray-500">
                                            Your balance <span className="text-lg text-blue-500">{walletBalance ? walletBalance : 0}</span>
                                        </p>
                                        <p className="text-7xl text-gray-500">

                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <input
                                            type="number"
                                            name="wallet"
                                            value={walletAmount}
                                            onChange={(e) => setWalletAmount(e.target.value)}
                                            className={`block w-full rounded-md  border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]`}
                                            placeholder="Add amount"
                                        />
                                    </div>

                                    <div className="mt-4 flex gap-5">
                                        <button
                                            type="button"
                                            disabled={walletBalance === 0 ? true : false}
                                            className={`${walletBalance===0?"bg-blue-100 text-blue-900 opacity-50 cursor-not-allowed":"bg-blue-100 text-blue-900"} inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  `}
                                            onClick={()=>withDrawHandler()}
                                        >
                                            WithDraw
                                        </button>
                                        {/* {
                                            walletBalance === 0 &&
                                            ( */}
                                                <button
                                                    type="button"
                                                    // disabled={walletBalance === 0 ? false : true}
                                                    className={`bg-gray-400 text-black cursor-pointer inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  `}
                                                    onClick={closeModal}
                                                >
                                                    Cancel
                                                </button>
                                            {/* )
                                        } */}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}


export default UseWallet