import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";
import { useNavigate, useParams } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";


const AddWalletAmount = () => {
    const [selectCustomer, setSelectCustomer] = useState(null);
    const [customerOption, setCustomerOption] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectAmountType, setSelectAmountType] = useState({})
    const navigate = useNavigate();
    
    const [walletBalance, setWalletBalance] = useState()
    const [submitLoader, setSubmitLoader] = useState(false)

    const formValues = {
        user: "",
        amountType: "",
        amountValue: "",
        eventName: "",


    }
    const [initialValues, setInitialValues] = useState(formValues);

    const AmountType = [
        {
            label: "Credit",
            value: '1'
        },
        {
            label: "Debit",
            value: '2'
        },
    ]
    useEffect(() => {
        const fetchData = async () => {
            await getUsers();

        }
        fetchData()
    }, [])

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        //console.log("response", response?.data?.data)

        if (response?.data?.data?.length > 0) {
            const optionData = []


            response?.data?.data.forEach((ele) => {


                optionData.push({
                    value: ele?.u_user_id,
                    fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
                    email: ele?.u_user_email,
                    walletAmount: ele?.m_wallet_balance
                })

            })
            setCustomerOption(optionData)
        } else {
            setCustomerOption([])
        }
    }




    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,

    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {

            if(selectAmountType?.label==="Debit"&&walletBalance<=0){
                toast.info("Cant Debit Wallet Balance is 0 ")
            }else{
                
    
                const body = {
                    user: selectCustomer.value?String(selectCustomer.value):null,
                    amountType: selectAmountType?.label ? selectAmountType?.label : null,
                    amountValue: values.amountValue,
                    eventName: values.eventName,
                }
    
    
                addWalletHandler(body)
    
                console.log("body", body)
            }
            


        }
    })



    const addWalletHandler = async (payload) => {

        console.log("body", payload)
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addWallet(payload);
            console.log("addWalletHandler", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                
                navigate("/wallet")
            } else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            
            toast.error(error?.data?.message)
        }
    }




    useEffect(() => {
        if (customerOption?.length > 0) {
            setLoading(false);
        }

    }, [customerOption]);

    const clearForm = () => {
        navigate("/wallet")

    }

    console.log("walletBalance",walletBalance)
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Wallet</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 "
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit()
                }}>
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Wallet Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg px-5 py-8 shadow-sm">


                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                User
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    name="selectCustomer"
                                    value={selectCustomer}
                                    getOptionLabel={(option) => option?.fullName ? `${option?.fullName} (${option?.email})` : ""}
                                    getOptionValue={(option) => option.value}

                                    onChange={(selectOption) => {
                                        const wAmount = selectOption?.walletAmount ? selectOption?.walletAmount : 0
                                        console.log("setSelectCustomer", selectOption,wAmount)
                                        setSelectCustomer(selectOption)
                                        setWalletBalance(wAmount)

                                    }
                                    }
                                    className="basic-single sm:text-sm"
                                    options={customerOption}
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Credit/Debit
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectAmountType}
                                    onChange={(selectedOption) => {
                                        setSelectAmountType(selectedOption)
                                    }}
                                    options={AmountType}
                                    name="AmountType"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Enter Amount
                            </label>
                            <div className="flex">
                                <input
                                    type="number"
                                    name="amountValue"
                                    value={values.amountValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />


                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Event Name
                            </label>
                            <div className="flex">
                                <input
                                    type="text"
                                    name="eventName"
                                    value={values.eventName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />
                            </div>
                        </div>
                        {
                            selectCustomer ?
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Your wallet balance
                                    </label>
                                    <div className="flex">
                                        <input
                                            type="text"
                                            readOnly
                                            name="walletBalance"
                                            value={walletBalance}
                                            
                                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        />
                                    </div>
                                </div>:<></>
                        }


                    </div>
                </div>
                <div className="flex gap-5 justify-end  ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="button"
                        
                        onClick={() => handleSubmit()}
                        className="text-[#fff] bg-[#3a4b43] cursor-pointer rounded-md border   shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }
        </>
    )
}

export default AddWalletAmount