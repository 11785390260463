import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";
import { useNavigate, useParams } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { RotatingLineSpinner } from "../../../components/Spinner";


const AddVoucher = () => {
    const formValues = {
        m_voucher_code: "",
        m_discounted_value: "",
        m_expiry_date: "",
        m_applicable_day: "",
        is_flat: "",
        is_active:""

    }
    const [initialValues, setInitialValues] = useState(formValues);
    const [selectIsFlat, setSelectIsFlat] = useState(null);
    const [selectApplicableDay, setSelectApplicableDay] = useState(null);
    const [editVoucherData, setEditVoucherData] = useState([]);
    const [selectStatus,setSelectStatus] = useState(null)
    const { voucher_id } = useParams();
    const [submitLoader, setSubmitLoader] = useState(false)

    const navigate = useNavigate();

    const isFlatOption = [
        {
            value: "Y",
            label: "Yes"
        },
        {
            value: "N",
            label: "No"
        },
    ]
    const statusOption = [
        {
            value: "Y",
            label: "Active"
        },
        {
            value: "N",
            label: "Inactive"
        },
    ]
    const applicableDayOption = [
        {
            value: "All",
            label: "All"
        },
    ]

    const getSingleVoucherByID = async (id) => {
        try {
            const response = await MasterServices.getSingleVoucher(id);
            console.log("getSingleVoucher", response)
            if (response?.data?.data?.length > 0) {
                //console.log("response", response)
                setEditVoucherData(response?.data?.data)
            } else {
                setEditVoucherData([])
            }
        } catch (error) {
            console.log("getSingleVoucher", error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (voucher_id) {
                await getSingleVoucherByID(voucher_id)
            }
        }
        fetchData()
    }, [voucher_id])

    useEffect(() => {
        if (editVoucherData?.length > 0) {
            console.log("editVoucherData", editVoucherData)

            editVoucherData?.forEach((item) => {
              
                const timestamp = item?.m_expiry_date;
                const date = new Date(timestamp);
                const formattedTimestamp = date.toISOString().slice(0, 16);

                console.log("m_expiry_date",formattedTimestamp)
                setInitialValues({
                    m_voucher_code: item?.m_voucher_code,
                    m_discounted_value: item?.m_discounted_value,
                    m_expiry_date: formattedTimestamp,

                })

                setSelectApplicableDay({
                    label: item?.m_applicable_day,
                    value: item?.m_applicable_day,
                })

                setSelectIsFlat({
                    label: item?.is_flat === "Y" ? "Yes" : "No",
                    value: item?.is_flat === "Y" ? "Y" : "N"
                })

                setSelectStatus(({
                    label:item?.is_active==="Y"?"Active":"Inactive",
                    value:item?.is_active
                }))
            })
        }
    }, [editVoucherData])

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {
            const body = {
                m_voucher_code: values.m_voucher_code,
                m_voucher_type: values.m_voucher_code,
                m_discounted_value: values.m_discounted_value,
                m_expiry_date: values.m_expiry_date,
                m_applicable_day: selectApplicableDay?.value ? selectApplicableDay?.value : null,
                is_flat: selectIsFlat?.value ? selectIsFlat?.value : null,
                m_voucher_id: voucher_id ? parseInt(voucher_id) : null,
                is_active: selectStatus?.value ? selectStatus?.value :null
            }

            console.log("body", body)
            if (editVoucherData?.length > 0) {
                editVoucherButton(body)
            } else {
                addVoucherButton(body)
            }

        }
    })

    const editVoucherButton = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editVoucher(payload);
            console.log("editVoucherButton", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/voucher")
            }else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("editVoucherButton", error)
        }
    }
    const addVoucherButton = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addVoucher(payload);
            console.log("addVoucherButton", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/voucher")
            }else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("addVoucherButton", error)
        }
    }
    const clearForm = () => {
        navigate("/voucher")

    }

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{voucher_id ? "Edit Voucher" : "Add Voucher"}</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 "
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit()
                }}>
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Voucher Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg px-5 py-8 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Voucher Code
                            </label>
                            <div className="">
                                <input
                                    value={values.m_voucher_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="m_voucher_code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Voucher code"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Discount Value
                            </label>
                            <div className="">
                                <input
                                    value={values.m_discounted_value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    min="0"
                                    name="m_discounted_value"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Discount value"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Expiry Date
                            </label>
                            <div className="flex">
                                <input
                                    type="datetime-local"
                                    name="m_expiry_date"
                                    value={values.m_expiry_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />


                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Applicable Day
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectApplicableDay}
                                    onChange={(selectedOption) => {
                                        setSelectApplicableDay(selectedOption)
                                    }}
                                    options={applicableDayOption}
                                    name="m_applicable_day"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                IS_Flat
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectIsFlat}
                                    onChange={(selectedOption) => {
                                        setSelectIsFlat(selectedOption)
                                    }}
                                    options={isFlatOption}
                                    name="is_flat"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Status
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectStatus}
                                    onChange={(selectedOption) => {
                                        setSelectStatus(selectedOption)
                                    }}
                                    options={statusOption}
                                    name="is_active"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end  ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddVoucher